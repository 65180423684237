import './line.css';

function Line() {
    return(
        <div className="line"></div>
    );
}

export default Line;



