import React from 'react';

const Componente1 = () => {
  return (
    <div className="componente">
      <h2>Componente 1</h2>
      <p>Conteúdo do Componente 1</p>
      <h1>R$ 1 = 1 ponto
      que não expira</h1>
      <p>Assim que cada compra é confirmada, você já pode usar seus pontos. Todo real investido em serviços da M3D, volta pra você em pontos!</p>
    </div>
  );
};

export default Componente1;
