import './btn-render.css';


function BtnRender() {
    return(
        <>
            <button type="button" className='btn-render'>QUERO RENDERIZAR PROJETO</button>
        </>
    );
}

function BtnRenderProj() {
    return(
        <>
            <button type="button" className='btn-render-proj'>QUERO RENDERIZAR PROJETO</button>
        </>
    );
}

function BtnRenderStudio() {
    return(
        <>
            <button type="button" className='btn-render-studio'>QUERO RENDERIZAR PROJETO</button>
        </>
    );
}

export  {BtnRender, BtnRenderProj, BtnRenderStudio};