import React, { useState } from 'react';

const FaixaPontos1 = () => {
  
  return (
    <div className="componente">
      <h2>1000 pnts</h2>
      <p>Conteúdo do Componente 2</p>
   
      </div>
   
  );
};

export default FaixaPontos1;
