import React, { useState } from 'react';

const FaixaPontos2 = () => {
  
  return (
    <div className="componente">
      <h2>1800pnts</h2>
      <p>Conteúdo do Componente 2</p>

      </div>
   
  );
};

export default FaixaPontos2;
