import './lineabsolute.css';

function LineAbsoluteHome() {
    return(
        <div className="lineabsolute"></div>
    );
}

function LineAbsoluteProj() {
    return(
        <div className="lineabsoluteproj"></div>
    );
}



export { LineAbsoluteHome, LineAbsoluteProj };




