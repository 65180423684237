import React, { useState } from 'react';
import Masonry, {ResponsiveMasonry} from 'react-responsive-masonry';

import Img1 from '../m3dasset/img1-home.jpg';
import Img2 from '../m3dasset/img2-home.jpg';
import Img3 from '../m3dasset/img3-home.png';
import Img4 from '../m3dasset/img4-home.jpg';
import Img5 from '../m3dasset/img5-home.jpg';
import Img6 from '../m3dasset/img6-home.jpg';
import Img7 from '../m3dasset/img7-home.jpg';
import Img8 from '../m3dasset/img8-home.jpg';
import Img9 from '../m3dasset/img9-home.jpg';
import Img10 from '../m3dasset/img10-home.png';
import Img11 from '../m3dasset/img11-home.jpg';
import Img12 from '../m3dasset/img12-home.jpg';
import Img13 from '../m3dasset/img13-home.jpg';
import Img14 from '../m3dasset/img14-home.jpg';
import Img15 from '../m3dasset/img15-home.jpg';
import Img16 from '../m3dasset/img16-home.jpg';
import Img17 from '../m3dasset/img17-home.jpg';
import Img18 from '../m3dasset/img18-home.png';
import Img19 from '../m3dasset/img19-home.jpg';
import Img20 from '../m3dasset/img20-home.jpg';
import Img21 from '../m3dasset/img21-home.jpg';

import ImgVertical01 from '../m3dasset/img1-vertical-home.png';
import ImgVertical02 from '../m3dasset/img2-vertical-home.png';
import ImgVertical03 from '../m3dasset/img3-vertical-home.png';
import ImgVertical04 from '../m3dasset/img4-vertical-home.png';
import ImgVertical05 from '../m3dasset/img5-vertical-home.png';
import ImgVertical06 from '../m3dasset/img6-vertical-home.png';
import ImgVertical07 from '../m3dasset/img7-vertical-home.png';

import img1verticalnormal from '../m3dasset/img1verticalhomenormal.jpg';
import img2verticalnormal from '../m3dasset/img2verticalhomenormal.jpg';
import img3verticalnormal from '../m3dasset/img3verticalhomenormal.jpg';
import img4verticalnormal from '../m3dasset/img4verticalhomenormal.jpg';
import img5verticalnormal from '../m3dasset/img5verticalhomenormal.jpg';
import img6verticalnormal from '../m3dasset/img6verticalhomenormal.jpg';
import img7verticalnormal from '../m3dasset/img7verticalhomenormal.jpg';




import './gallery.css';


import {MdNavigateNext, MdClose} from 'react-icons/md';
import {RiArrowLeftSLine} from 'react-icons/ri';
import { IconContext } from 'react-icons';

const images = [
  Img1,
  ImgVertical01,
  Img2,

  ImgVertical02,
  Img3,
  ImgVertical03,
  
  Img4,
  ImgVertical04,
  Img5,

  Img6,
  Img7,
  ImgVertical05,

  ImgVertical06,
  Img8,
  Img9,

  /*novo abaixo*/ 
  Img10,
  ImgVertical07,
  Img11,

 Img12,
  Img13,
  Img14,
  
  Img15,
  Img16,

  Img17,
  Img18,

  Img19,
  Img20,


]

const imageMap = {
  0: Img1,
  1: img1verticalnormal,
  2: Img2,

  3: img2verticalnormal,
  4: Img3,
  5: img3verticalnormal,
  
  6: Img4,
  7: img4verticalnormal,
  8: Img5,

  9: Img6,
  10: Img7,
  11: img5verticalnormal,

  12: img6verticalnormal,
  13: Img8,
  14: Img9,

  15: Img10,
  16: img7verticalnormal,
  17: Img11,

  18: Img12,
  19: Img13,
  20: Img14,

  21: Img15,
  22: Img16,
  23: Img17,

  24: Img18,
  25: Img19,
  26: Img20,

};


const Gallery = () => {

    const [data, setData] = useState({img: '', i: 0})  

    const viewImage = (img, i)=>{
        const modalImage = imageMap[i] || img; // Exibe a imagem mapeada ou a própria imagem se não houver mapeamento
        setData({ img: modalImage, i });
    }

    const imgAction = (action) => {
        let i = data.i;
        if (action === 'next-img') {
          setData({ img: images[i + 1], i: i + 1 });
        }
        if (action === 'previous-img') {
          setData({ img: images[i - 1], i: i - 1 });
        }
        if (!action) {
          setData({ img: '', i: 0 });
        }
      };

    return(
       
        <>
            {data.img &&
                <>
                    <div className='div-out'>
                        <img src={data.img} className='img-out' alt=''/>
                        <IconContext.Provider value={{ className: 'itprevious', color: 'white'}}>
                            <RiArrowLeftSLine 
                                onClick={() => imgAction('previous-img')}/>
                        </IconContext.Provider>
                        <IconContext.Provider value={{ className: 'it' }}>
                            <MdClose onClick={() => imgAction()}/>
                        </IconContext.Provider>
                        <IconContext.Provider value={{ className: 'itnext' }}>
                                <MdNavigateNext        
                                onClick={() => imgAction('next-img')}/>
                        </IconContext.Provider>
                    </div>
                </>
            }
            <div className='gallery-global'>
                <ResponsiveMasonry 
                    columnsCountBreakPoints={{350: 1, 750: 2, 900:3}}>
                        <Masonry gutter='20px'>
                            {
                                images.map((image, i) => (
                                    <img 
                                        key={i}
                                        src={image}
                                        className='img-responsive'
                                        alt=""
                                        onClick={()=> viewImage(image, i)}
                                    />
                                ))
                            }
                        </Masonry>
                </ResponsiveMasonry>
            </div>
        </>
    );
}

export default Gallery;




